/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  CardMedia
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Truck as TruckIcon,
  Package as PackageIcon,
  DownloadCloud as DownloadCloudIcon,
  Navigation as NavigationIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { User } from 'src/types/user';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Company',
        icon: BriefcaseIcon,
        href: '/app/management/company',
        items: [
          {
            title: 'Maintain Company',
            href: '/app/management/company'
          }
        ]
      },
      {
        title: 'Loads',
        icon: PackageIcon,
        href: '/app/management/loads',
        items: [
          {
            title: 'New Load',
            href: '/app/management/loads/create'
          },
          {
            title: 'Load Search',
            href: '/app/management/loads'
          }
          // {
          //   title: 'View Load',
          //   href: '/app/management/customers/1'
          // },
          // {
          //   title: 'Edit Load',
          //   href: '/app/management/customers/1/edit'
          // }
        ]
      },
      {
        title: 'Drivers',
        icon: UsersIcon,
        href: '/app/management/drivers',
        items: [
          {
            title: 'Maintain Drivers',
            href: '/app/management/drivers'
          },
          {
            title: 'Driver Settlements',
            href: '/app/reports/driverrevenue'
          },
          {
            title: 'Driver Expenses',
            href: '/app/management/drivers/expenses'
          }
        ]
      },
      {
        title: 'Dispatch',
        icon: NavigationIcon,
        href: '/app/management/drivers/dispatch',
        items: [
          {
            title: 'Dispatch List',
            href: '/app/management/drivers/dispatch'
          },
        ]
      },
      {
        title: 'Equipment',
        icon: TruckIcon,
        href: '/app/management/equipment/trucks',
        items: [
          {
            title: 'Trucks',
            href: '/app/management/equipment/trucks'
          },
          {
            title: 'Truck Expenses',
            href: '/app/management/equipment/trucks/expenses'
          }
        ]
      },
      // {
      //   title: 'Brokers/Partners',
      //   icon: UsersIcon,
      //   href: '/app/management/partners',
      //   items: [
      //     {
      //       title: 'List Contacts',
      //       href: '/app/management/partners'
      //     }
      //   ]
      // },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'Search Invoices',
            href: '/app/management/invoices'
          }
        ]
      },
      {
        title: 'Copilot',
        icon: DownloadCloudIcon,
        href: '/app/management/loads/createocr',
        items: [
          {
            title: 'Rate Confirmations',
            href: '/app/management/loads/createocr'
          }
        ]
      },
      {
        title: 'Finances',
        icon: DollarSignIcon,
        href: '/app/finance/companyexpenses',
        items: [
          {
            title: 'Company Expenses',
            href: '/app/finance/companyexpenses'
          },
          {
            title: 'Company Settlements',
            href: '/app/reports/companysettlement'
          },
          {
            title: 'Plaid Integration',
            href: '/app/finance/plaidintegration'
          }
        ]
      },
      {
        title: 'Compliance',
        icon: FolderIcon,
        href: '/app/management/compliance/documentation',
        items: [
          {
            title: 'Documentation',
            href: '/app/management/compliance/documentation'
          },
          {
            title: 'IFTA',
            href: '/app/reports/ifta'
          }
        ]
      }
    ]
  }
  // {
  //   subheader: 'Applications',
  //   items: [
  //     {
  //       title: 'Projects Platform',
  //       href: '/app/projects',
  //       icon: BriefcaseIcon,
  //       items: [
  //         {
  //           title: 'Overview',
  //           href: '/app/projects/overview'
  //         },
  //         {
  //           title: 'Browse Projects',
  //           href: '/app/projects/browse'
  //         },
  //         {
  //           title: 'Create Project',
  //           href: '/app/projects/create'
  //         },
  //         {
  //           title: 'View Project',
  //           href: '/app/projects/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social Platform',
  //       href: '/app/social',
  //       icon: ShareIcon,
  //       items: [
  //         {
  //           title: 'Profile',
  //           href: '/app/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           href: '/app/social/feed'
  //         }
  //       ]
  //     }
      // {
      //   title: 'Kanban',
      //   href: '/app/kanban',
      //   icon: TrelloIcon
      // },
      // {
      //   title: 'Mail',
      //   href: '/app/mail',
      //   icon: MailIcon
      // },
      // {
      //   title: 'Chat',
      //   href: '/app/chat',
      //   icon: MessageCircleIcon,
      //   info: () => (
      //     <Chip
      //       color="secondary"
      //       size="small"
      //       label="Updated"
      //     />
      //   )
      // },
      // {
      //   title: 'Calendar',
      //   href: '/app/calendar',
      //   icon: CalendarIcon,
      //   info: () => (
      //     <Chip
      //       color="secondary"
      //       size="small"
      //       label="Updated"
      //     />
      //   )
      // }
    // ]
  // }
  // {
  //   subheader: 'Auth',
  //   items: [
  //     {
  //       title: 'Login',
  //       href: '/login-unprotected',
  //       icon: LockIcon
  //     },
  //     {
  //       title: 'Register',
  //       href: '/register-unprotected',
  //       icon: UserPlusIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Pages',
  //   items: [
  //     {
  //       title: 'Account',
  //       href: '/app/account',
  //       icon: UserIcon
  //     },
  //     {
  //       title: 'Error',
  //       href: '/404',
  //       icon: AlertCircleIcon
  //     },
  //     {
  //       title: 'Pricing',
  //       href: '/pricing',
  //       icon: DollarSignIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Extra',
  //   items: [
  //     {
  //       title: 'Charts',
  //       href: '/app/extra/charts',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Apex Charts',
  //           href: '/app/extra/charts/apex'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Forms',
  //       href: '/app/extra/forms',
  //       icon: EditIcon,
  //       items: [
  //         {
  //           title: 'Formik',
  //           href: '/app/extra/forms/formik'
  //         },
  //         {
  //           title: 'Redux Forms',
  //           href: '/app/extra/forms/redux'
  //         },
  //       ]
  //     },
      // {
      //   title: 'Editors',
      //   href: '/app/extra/editors',
      //   icon: LayoutIcon,
      //   items: [
      //     {
      //       title: 'DraftJS Editor',
      //       href: '/app/extra/editors/draft-js'
      //     },
      //     {
      //       title: 'Quill Editor',
      //       href: '/app/extra/editors/quill'
      //     }
        // ]
      // }
    // ]
  // }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 74,
    height: 74
  }
}));



const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user, company } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getStatusLabel = (companyUrl: string, user: User): JSX.Element => {

    if(!companyUrl || companyUrl == "")
    {
      return (
        <RouterLink to="/app/account">
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user.avatar}
        />
      </RouterLink>
      );
    }
    else
    {
      return (
          <Box height="45%" width="55%" mt={3}>
              <CardMedia
                component="img"
                src={companyUrl}
                height= '100%'
                width= '100%'
              />
          </Box>
      );
    }
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            {getStatusLabel((company?.companyLogoUrl ? company.companyLogoUrl : ""), user)}
            {/* <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={company?.companyLogoUrl ? company.companyLogoUrl : user.avatar}
              />
            </RouterLink> */}
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.companyName}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box> */}
        {/* </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
