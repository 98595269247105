import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { LoginResult, User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from 'axios';
import { EntityCompany } from 'src/types/company';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  company: EntityCompany | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
  reloadCompany: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    company: EntityCompany | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    company: EntityCompany;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type ReloadCompanyAction = 
{
  type: 'RELOADCOMPANY';
  payload: {
    company: EntityCompany;
  };
}

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | ReloadCompanyAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  company: null,
};

const isValidToken = (accessToken: string): boolean => {
  if (accessToken) {
    return true;
  }
  else
  {
    return false;
  }
  
  
  //const decoded: any = jwtDecode(accessToken);
  //const currentTime = Date.now() / 1000;

  //return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, company } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        company
      };
    }
    case 'LOGIN': {
      const { user, company } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        company
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        company: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'RELOADCOMPANY': {
      const { company } = action.payload;

      return {
        ...state,
        company
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  reloadCompany: () => Promise.resolve()
});


export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const myAxiosInstance = axiosInstance.create();
  
  const loadCompanyInfo = async () : Promise<EntityCompany> => {
      myAxiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
      const response = await myAxiosInstance.get<EntityCompany>("https://trackyourtruckingapi.azurewebsites.net/api/company");
      return response.data;
  };

  const reloadCompany = async () =>
  {
    var company = await loadCompanyInfo();

    dispatch({
      type: 'RELOADCOMPANY',
      payload: {
        company
      }
    });
  };

  const login = async (email: string, password: string) => {
    const response = await myAxiosInstance.post<LoginResult>('https://trackyourtruckingapi.azurewebsites.net/api/user/login', null, {headers:  {'Username': email, 'Password': password}});
    //{ userToken: string, firstName: string, lastName: string, companyName: string}
    //const { accessToken, user } = response.data;
    const accessToken = response.data.user.userToken;
    setSession(accessToken);

    //var company = await loadCompanyInfo();

    const user = {
            id: uuidv4(),
            avatar: null,
            canHire: false,
            country: null,
            email,
            isPublic: true,
            name: response.data.user.firstName + " " + response.data.user.lastName,
            password,
            phone: null,
            role: 'admin',
            state: null,
            tier: 'Standard',
            companyName: response.data.user.companyName
          };

    
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        company: response.data.company
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user: User }>('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          myAxiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
          const response = await myAxiosInstance.get<LoginResult>('https://trackyourtruckingapi.azurewebsites.net/api/user');

          const user = {
            id: uuidv4(),
            avatar: null,
            canHire: false,
            country: null,
            email: response.data.user.emailAddress,
            isPublic: true,
            name: response.data.user.firstName + " " + response.data.user.lastName,
            phone: null,
            role: 'admin',
            state: null,
            tier: 'Standard',
            companyName: response.data.user.companyName
          };
          
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user : user,
              company: response.data.company
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              company: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            company: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        reloadCompany
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;